//TODO: create in same way as others: gatsby-node.js
import React from "react";
import LayoutDefault from "../componentsxx/LayoutDefault";
import { SEO } from "../componentsxx/SEO";
import ServicesBody from "../componentsxx/ServicesBody";
import { textObj } from "../text/text";

//TODO: For now just pointing to card data
const { headerText, descriptionText } = textObj.servicesSection.cardDataText[4];

const ConcretePage = () => {
  return (
    <LayoutDefault>
      <ServicesBody headerText={headerText} descriptionText={descriptionText} />
    </LayoutDefault>
  );
};

export default ConcretePage;
export const Head = () => (
  <SEO
    title="Expert Concrete Service In PA"
    description="As professional concrete contractors, we offer concrete construction services in PA since 2017."
  />
);
